import React, { Component } from "react";
import NavigationBar from "../../NavigationBar";
import { saveAs } from "file-saver";
import uuid from "react-uuid";
import Button from 'react-bootstrap/Button';
import GoogleAd from "./../../components/GoogleAd";

class Base64ToImage extends Component {
  state = {
    value: ``,
    output: ``,
    opdisplay: false,
    validBase64: false,
  };

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
    console.log('handle '+this.state.validBase64);
  };

  base64ToImage = (event) => {
    this.setState({
      ...this.state,
      output: `data:image/png;base64,` + this.state.value,
      opdisplay: true,
      validBase64: this.isValidBase64(this.state.value),
    });
    console.log('base64ToImage '+this.state.validBase64);
    event.preventDefault();
  };

  isValidBase64(str) {
    if(str ===''){
        return false;
    }
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  clearOp = () => {
    this.setState({ value: ``, output: ``, opdisplay: false,validBase64: this.isValidBase64(this.state.value)});
  };

  downloadImage = () => {
    saveAs(this.state.output, uuid() + `.png`); // Put your image url here.
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          <h2>Base64 to Image</h2>
          <div className="form-group">
            <textarea
              className="form-control rounded-0 w-50"
              name="value"
              rows="8"
              placeholder="Copy and Paste your Base64 String."
              value={this.state.value}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="col-md-4">
            <button
              style={{ marginTop: "6px" }}
              onClick={this.base64ToImage}
              className="col-md-2 btn btn-outline-dark"
            >
              Convert
            </button>
            <button
              style={{ marginTop: "6px" }}
              onClick={this.clearOp}
              className="col-md-2 btn btn-outline-dark"
            >
              Clear
            </button>
          </div>
          {this.state.opdisplay ? (
            this.state.validBase64 ? (
              <div
                className="container-md border"
                style={{ marginLeft: "30px", marginTop: "20px" }}
              >
                <div>
                  <Button
                    className="outline-primary"
                    onClick={this.downloadImage}
                  >
                    Download!
                  </Button>
                </div>
                <div>
                  <img src={this.state.output} alt="base64 to img" />
                </div>
              </div>
            ) : (
              <div>
                <h2>Invalid base64 string!</h2>
              </div>
            )
          ) : null}
        </div>
        <GoogleAd/>
      </React.Fragment>
    );
  }
}

export default Base64ToImage;
