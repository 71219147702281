import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NavigationBar from "../../NavigationBar";

class JsontoXml extends Component {
  state = {
    value: `{"id":1, "name":"John"}`,
    output: `<id>1</id>
<name>John</name>`,
    copied: false,
    reset: false,
  };

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
    this.setState({ copied: false });
  };

  clearOp = () => {
    this.setState({ output: "", copied: false, reset: false });
  };

  clearIn = () => {
    this.setState({ value: "", copied: false, reset: true });
  };

  formatJson = () => {
    this.setState({ value: JSON.stringify(this.state.value, null, 2) });
  };

  convertJsontoXml = (event) => {
    if (!this.state.reset) {
      try {
        let convert = require("xml-js");
        let result = convert.json2xml(this.state.value, {
          compact: true,
          spaces: 4,
        });
        this.setState({
          output: result,
          copied: false,
        });
      } catch (Error) {
        console.log(Error);
        alert("Error in input");
      }
    }
    this.setState({ reset: false });
    event.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          <h2>JSON to XML</h2>
          <form onSubmit={this.convertJsontoXml}>
            <div className="form-group">
              <div
                className="row col-md-6 no-gutters d-flex justify-content-end"
                style={{ marginBottom: "6px" }}
              >
                <div className="col-md-1">
                  <button
                    className="btn btn-outline-dark"
                    onClick={this.clearIn}
                  >
                    Clear
                  </button>
                </div>
                {/* <div className="col-md-1">
                  <button className="btn btn-outline-dark"
                  onClick={this.formatJson}>Format</button>
                </div> */}
              </div>
              <textarea
                className="form-control rounded-0 w-50"
                name="value"
                rows="8"
                placeholder="Copy and Paste your CSV."
                value={this.state.value}
                onChange={this.handleChange}
              ></textarea>
              <button
                style={{ marginTop: "6px", marginBottom: "6px" }}
                className="btn btn-outline-dark"
              >
                Convert
              </button>
            </div>
          </form>
          <textarea
            className="form-control rounded-0 w-50"
            rows="8"
            placeholder="Output here"
            value={this.state.output}
            readOnly
          ></textarea>
          <div className="row">
            <div className="col-md-4">
              <button
                style={{ marginTop: "6px" }}
                onClick={this.clearOp}
                className="col-md-2 btn btn-outline-dark"
              >
                Clear
              </button>
              <CopyToClipboard
                text={this.state.output}
                onCopy={() => this.setState({ copied: true })}
              >
                <button
                  style={{ marginTop: "6px" }}
                  className="col-md-2 btn btn-outline-dark"
                >
                  Copy
                </button>
              </CopyToClipboard>

              {this.state.copied ? (
                <span className="col-md-4" style={{ color: "green" }}>
                  Copied to clipboard.
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JsontoXml;
