import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./Main";
import CsvtoXML from "./screens/converters/CsvtoXML";
import JsontoJsonString from "./screens/converters/JsontoJsonString";
import StringtoJson from "./screens/converters/StringtoJson";
import CsvtoJSON from "./screens/converters/CsvtoJSON";
import JsontoXML from "./screens/converters/JsontoXml";
import XmltoJson from "./screens/converters/XmltoJson";
import JsonFormat from "./screens/formatters/JsonFormat";
import Base64ToImage from "./screens/converters/Base64ToImage";
import FileToBase64 from "./screens/converters/FileToBase64";
import MultiToSingleLine from "./screens/converters/MultiToSingleLine";
import XmlFormat from "./screens/formatters/XmlFormat";

class App extends Component {
  state = {};
  
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Main} exact={true} />
          <Route path="/jsontostring" component={JsontoJsonString} />
          <Route path="/stringtojson" component={StringtoJson} />
          <Route path="/csvtoxml" component={CsvtoXML} />
          <Route path="/csvtojson" component={CsvtoJSON} />
          <Route path="/jsontoxml" component={JsontoXML} />
          <Route path="/xmltojson" component={XmltoJson} />
          <Route path="/base64toimage" component={Base64ToImage} />
          <Route path="/filetobase64" component={FileToBase64} />
          <Route path="/multitosingleline" component={MultiToSingleLine} />
          <Route path="/formatjson" component={JsonFormat} />
          <Route path="/formatxml" component={XmlFormat} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
