import { Component } from "react";
import React from "react";
import NavigationBar from "../../NavigationBar";
import { CopyToClipboard } from "react-copy-to-clipboard";

class FileToBase64 extends Component {
  state = {
    base64String: ``,
    opdisplay: false,
    copied:false
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({ base64String: reader.result, opdisplay: true });
      };
    }
    event.preventDefault();
  };

  clearOp = (event) => {
    this.setState({ base64String: ``, opdisplay: false ,copied:false,value:``});
  };
  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          <h2>File to Base64</h2>
          <div className="form-group">
            <input type="file" onChange={this.handleImageChange}/>
          </div>
          <textarea
            cols={100}
            rows={8}
            value={this.state.base64String}
            style={{ marginTop: "6px" }}
            readOnly
          />
          <div className="col-md-4">
            <button
              style={{ marginTop: "6px" }}
              onClick={this.clearOp}
              className="col-md-2 btn btn-outline-dark"
            >
              Clear
            </button>
            <CopyToClipboard
                text={this.state.base64String}
                onCopy={() => this.setState({ copied: true })}
              >
                <button
                  style={{ marginTop: "6px" }}
                  className="col-md-2 btn btn-outline-dark"
                >
                  Copy
                </button>
              </CopyToClipboard>

              {this.state.copied ? (
                <span className="col-md-4" style={{ color: "green" }}>
                  Copied to clipboard.
                </span>
              ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default FileToBase64;
