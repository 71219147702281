import React, { Component } from "react";
import NavigationBar from "../../NavigationBar";
import { CopyToClipboard } from "react-copy-to-clipboard";

class MultiToSingleLine extends Component {
  state = {
    value: ``,
    clearOp: false,
    output: ``,
    copied: false,
  };

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  };

  convertMultiToSingle = (event) => {
    this.setState({
      output: this.state.value.replace(/\s+/g, " "),
    });
  };

  clearOp = () => {
    this.setState({ value: ``, output: ``, copied: false });
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          <h2>Multi Line to Single Line</h2>
          <div className="form-group">
            <textarea
              className="form-control rounded-0 w-50"
              name="value"
              rows="8"
              placeholder="Copy and Paste your Multi Line."
              value={this.state.value}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="col-md-4">
            <button
              style={{ marginTop: "6px", marginBottom: "6px" }}
              onClick={this.convertMultiToSingle}
              className="col-md-2 btn btn-outline-dark"
            >
              Convert
            </button>
            <button
              style={{ marginTop: "6px", marginBottom: "6px" }}
              onClick={this.clearOp}
              className="col-md-2 btn btn-outline-dark"
            >
              Clear
            </button>
          </div>
          <textarea
            className="form-control rounded-0 w-50"
            rows="8"
            placeholder="Output here"
            value={this.state.output}
            readOnly
          ></textarea>
          <div className="col-md-4">
            <CopyToClipboard
              text={this.state.output}
              onCopy={() => this.setState({ copied: true })}
            >
              <button
                style={{ marginTop: "6px" }}
                className="col-md-2 btn btn-outline-dark"
              >
                Copy
              </button>
            </CopyToClipboard>

            {this.state.copied ? (
              <span className="col-md-4" style={{ color: "green" }}>
                Copied to clipboard.
              </span>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MultiToSingleLine;
