import React, { Component } from 'react';
import { Navbar} from 'react-bootstrap';

class Footer extends Component {
  render() {
    return (
      <Navbar bg="dark" variant="dark" fixed="bottom" className="justify-content-center">
          <Navbar.Text>
            © mainutility.com {new Date().getFullYear()}. All rights reserved.
          </Navbar.Text>
      </Navbar>
    );
  }
}

export default Footer;