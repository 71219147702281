import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "react-bootstrap/Navbar";
import Footer from "./Footer";

class NavigationBar extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
          <Navbar.Brand style={{marginLeft:"15px"}} href="/">MAIN UTILTY</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          </Navbar.Collapse>
        </Navbar>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default NavigationBar;
