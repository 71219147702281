import React, { Component } from "react";

class HomeButton extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-info btn-block shadow-none"
          style={{ borderRadius: "5px", borderColor: "#D5A021" }}
        >
          {this.props.label}
          
        </button>
      </React.Fragment>
    );
  }
}

export default HomeButton;
