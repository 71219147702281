import React, { Component } from "react";
import NavigationBar from "./NavigationBar";
import Home from "./Home";

class Main extends Component {
  state = {

  };
  render() {
    return (
      <React.Fragment>
        <NavigationBar/>
        <Home/>  
      </React.Fragment>
    );
  }
}

export default Main;
