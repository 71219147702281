import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NavigationBar from "../../NavigationBar";

class JsontoJsonString extends Component {
  state = {
    value: `{
            "name": "john",
            "age": 22
}`,
    output: `"{\\"name\\":\\"john\\",\\"age\\":22}"`,
    copied: false,
    reset: false,
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value, copied: false });
  };

  handleSubmit = (event) => {
    if (!this.state.reset) {
      var myJSONString = JSON.stringify(this.state.value)
        .replaceAll(/\s/g, "")
        .replace(/\\n/g, "")
        .replace(/\\t/g, "");
      this.setState({ output: myJSONString, copied: false });
    }
    this.setState({ reset: false });
    event.preventDefault();
  };

  clearOp = () => {
    this.setState({ output: "", copied: false });
  };

  clearIn = () => {
    this.setState({ value: "", reset: true, copied: false });
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          <h2>JSON to String</h2>
          <form onSubmit={this.handleSubmit}>
            <div
              className="col-md-6 d-flex justify-content-end"
              style={{ marginBottom: "6px" }}
            >
              <div className="col-md-1">
                <button
                  className="btn btn-outline-dark"
                  onClick={this.clearIn}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="form-group">
              <textarea
                className="form-control rounded-0 w-50"
                rows="8"
                value={this.state.value}
                onChange={this.handleChange}
              ></textarea>
              <button
                style={{ marginTop: "6px", marginBottom: "6px" }}
                className="btn btn-outline-dark"
              >
                Convert
              </button>
            </div>
          </form>
          <textarea
            className="form-control rounded-0 w-50"
            rows="8"
            value={this.state.output}
            readOnly
          ></textarea>
          <div className="row">
            <div className="col-md-4">
              <button
                style={{ marginTop: "6px" }}
                onClick={this.clearOp}
                className="col-md-2 btn btn-outline-dark"
              >
                Clear
              </button>
              <CopyToClipboard
                text={this.state.output}
                onCopy={() => this.setState({ copied: true })}
              >
                <button
                  style={{ marginTop: "6px" }}
                  className="col-md-2 btn btn-outline-dark"
                >
                  Copy
                </button>
              </CopyToClipboard>

              {this.state.copied ? (
                <span className="col-md-4" style={{ color: "green" }}>
                  Copied to clipboard.
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JsontoJsonString;
