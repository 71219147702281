import { useHistory } from "react-router-dom";
import React, { useState } from "react";

export function NavButton(props) {
  let history = useHistory();
  const [color, setColor] = useState("");
  const styles = {
    marginTop: "5px",
    color: color,
  };

  function handleClick() {
    history.push(props.pathName);
  }

  return (
    <div
      id={props.pathName}
      key={props.id}
      style={styles}
      onClick={handleClick}
      onMouseOver={() => setColor("green")}
      onMouseLeave={() => setColor("")}
    >
      <h6>{props.value}</h6>
    </div>
  );
}
