import React, { Component } from "react";
import { Redirect } from "react-router";
import {NavButton} from "./History";

class HomeFunctionList extends Component {
  state = {
    redirect: false,
    pathName: "",
  };
  handleOnClick = (event) => {
    
    this.setState({ redirect: true, pathName: event.target.id });
   
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.pathName,
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <div style={{ marginTop: "25px" }}>
          {this.props.list.map((val) => (
            <NavButton key={val.id} {...val}/>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default HomeFunctionList;
