import React, { Component } from "react";
import HomeButton from "./components/HomeButton";
import HomeFunctionList from "./components/HomeFunctionList";

class Home extends Component {
  state = {
    converters: [
      {
        id: 1,
        value: "JSON TO STRING",
        pathName:"/JsontoString",
      },
      {
        id: 2,
        value: "STRING TO JSON",
        pathName:"/stringtojson",
      },
      {
        id: 3,
        value: "CSV TO XML",
        pathName:"/csvtoxml",
      },
      {
        id: 4,
        value: "CSV TO JSON",
        pathName:"/csvtojson",
      },
      {
        id: 5,
        value: "XML TO JSON",
        pathName:"/xmltojson",
      },
      {
        id: 6,
        value: "JSON TO XML",
        pathName:"/jsontoxml",
      },
      {
        id: 7,
        value: "BASE64 TO IMAGE",
        pathName:"/base64toimage",
      },
      {
        id: 8,
        value: "FILE TO BASE64",
        pathName:"/filetobase64",
      },
      {
        id: 9,
        value: "MULTI TO SINGLE LINE ",
        pathName:"/multitosingleline",
      }
    ],
    formatters: [
      {
        id: 1,
        value: "FORMAT JSON",
        pathName:"/formatjson",
      },
      {
        id: 2,
        value: "FORMAT XML",
        pathName:"/formatxml",
      }
    ]
  };
  render() {
    return (
      <React.Fragment>
        <div className="container" style={{ marginTop: "10px" }}>
          <div className="row no-gutters">
            <div className="col-sm-2">
              <HomeButton label="Converters"></HomeButton>
              <HomeFunctionList list={this.state.converters}></HomeFunctionList>
            </div>
            <div className="col-sm-2">
              <HomeButton label="Formatters"></HomeButton>
              <HomeFunctionList list={this.state.formatters}></HomeFunctionList>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
